import clsx from 'clsx';
import { Wrapper } from 'components/Wrapper/Wrapper';

import { TopPageAdditionalImageCardProps } from 'lib/themes/themes';

import styles from './TopPageAdditionalImageCard.module.css';

export const TopPageAdditionalImageCard = ({
  additionalImage,
}: TopPageAdditionalImageCardProps) => {
  return (
    <Wrapper
      wrapper={(children) =>
        additionalImage?.link_url ? (
          <a className={styles['c-card-category']} href={additionalImage?.link_url}>
            {children}
          </a>
        ) : (
          <div className={styles['c-card-category']}>{children}</div>
        )
      }
    >
      <div className={styles['c-card-category__pic']}>
        <img src={additionalImage?.image_url} alt={additionalImage.title} />
      </div>
      <div className={styles['c-card-category__body']}>
        <h3 className={clsx(styles['c-card-category__body__ttl'], 'maincolor-txt')}>
          {additionalImage?.title}
        </h3>
        <p className={styles['c-card-category__body__description']}>
          {additionalImage?.description}
        </p>
      </div>
    </Wrapper>
  );
};

export default TopPageAdditionalImageCard;
